import React from "react";
import wavy_bg from "../images/wavy_bg.png";
import UNIFI_logo from "../images/UNIFI_logo.png";
import int_img_aboutunifi from "../images/int_img_aboutunifi.png";
import JRD_logo_notag_edit from "../images/JRD_logo_notag_edit.png";
import { Link } from "react-router-dom";

const UnifiAboutStudy = () => {
  return (
    <React.Fragment>
      <div className="main-container">
        <div className="main wrapper clearfix">
          <div className="mn">
            <div className="lg">
              <img alt="UNIFI Jr study logo" src={UNIFI_logo} />
            </div>
            <div className="mn_image_new">
              <img alt="wavy lines background" src={"/"+wavy_bg}/>
              {/* <div className="CRDPUChead_interior"> */}
              <div className="CRDPUChead">
                About the <span className="CRDPUC_high_3">UNIFI Jr</span>{" "}
                <span className="CRDPUC_high_2">study</span>
              </div>
              {/* <!--	</div> <!-- end of mn_image_new --> */}
            </div>
            {/* <!--
			</div> <!-- end main wrapper clearfix --> */}

            <div className="CRDPUC_copyholder_int">
              <div className="bodycopy" id="maincopy">
                <p>
                  The investigational medication being evaluated in this study
                  has not been approved to treat pediatric ulcerative colitis
                  patients. All patients who are eligible for this study and
                  agree to participate will initially receive a single
                  intravenous infusion of the investigational medication (also
                  known as an IV, which is when medication is delivered through
                  a small tube into a vein).
                </p>

                <p>
                  Following the infusion, patients will then be randomly
                  assigned (like flipping a coin) to 1 of 2 study groups.
                  Patients in both groups will receive subcutaneous injections
                  (needle inserted just under the skin) of the investigational
                  medication every 8 to 12 weeks for 44 weeks.{" "}
                </p>

                <p>
                  Total participation in this study will last approximately 74
                  weeks (about 1.5 years), which includes regular visits to the
                  study clinic for tests and assessments.
                </p>

                <p>
                  The results of this study will help doctors and researchers
                  learn more about the investigational medication and whether it
                  could one day potentially be used to treat ulcerative colitis
                  in children and adolescents.
                </p>
                <br />

                <p>
                  <div id="responsive-btn">
                    <Link
                      className="btn_uniti_research"
                      to="/UNIFIJR/about-research"
                    >
                      About Research Studies
                    </Link>{" "}
                  </div>
                  <div id="responsive-btn">
                    <Link className="btn_find_site" to="/find-a-site">
                      Find a study site
                    </Link>
                  </div>
                </p>
              </div>
            </div>
            <div className="int_pics">
              <img
                alt="illustration of doctor and pediatric patient"
                src={"/"+int_img_aboutunifi}
              />
            </div>
          </div>
        </div>

        <div className="footer-container">
          <footer className="wrapper clearfix">
            <div className="foot">
              <div className="JRD">
                <img alt="Janssen logo" src={"/"+JRD_logo_notag_edit} />
              </div>
              <Link to="/">Home</Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/UNIFIJR/about-study"> About UNIFI Jr</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/about-research">About Research Studies</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/privacy">Privacy Policy </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/legal"> Legal Notice</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <br />© Janssen Research & Development, LLC 2021. This site is
              hosted and operated by QD Solutions, Inc. on behalf of Janssen
              Research & Development, LLC, who is solely responsible for its
              content. The site is intended for US residents only. Last Update:
              26Apr2021
            </div>
          </footer>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UnifiAboutStudy;
