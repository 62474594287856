import React from "react";
import father_daughter_main from "../images/father_daughter_main.png";
import UNITI_logo from "../images/UNITI_logo.png";
import JRD_logo_notag_edit from "../images/JRD_logo_notag_edit.png";
import EmailFriend from "./EmailFriend";
import { Link } from "react-router-dom";

const UnitiHome = () => {
  return (
    <React.Fragment>
      <div className="main-container">
        <div className="main wrapper clearfix">
          <div className="mn">
            <div className="lg">
              <img alt="UNITI Jr study logo" src={UNITI_logo} />
            </div>
            <div className="mn_image_new">
              <img
                alt="illustration of father and daughter"
                src={"/"+father_daughter_main}
              />
              {/* <div className="CRDPUChead_uniti"> */}
              <div className="CRDPUChead">
                <span className="CRDPUC_high_2">Together</span>, we can take{" "}
                <span className="CRDPUC_high_1">Crohn’s disease</span> research
                further.
              </div>
            </div>

            <div className="CRDPUCsubhead">
              Learn more about the UNITI Jr research study for children and
              adolescents with{" "}
              <span className="CRDPUCsubhead_high">Crohn’s disease</span>.
            </div>
          </div>
          {/* <!--
			</div> <!-- end main wrapper clearfix --> */}

          <div className="copyholder_main">
            <div style={{ display: "inline" }}>
              <EmailFriend />
            </div>
            <div className="bodycopy" id="maincopy">
              <p>
                Although there are treatments available for pediatric Crohn’s
                disease patients, they aren’t always effective. As a result,
                there is a need to develop potential treatment options that may
                someday help these patients.{" "}
              </p>

              <p>
                In the UNITI Jr study, doctors and researchers want to evaluate
                the safety and effectiveness of an investigational medication
                for children and adolescents who have been diagnosed with
                Crohn’s disease.{" "}
              </p>

              <p>
                To pre-qualify for the UNITI Jr study, your child must:
                <ul>
                  <li>Be 2 to 17 years of age</li>
                  <li>Have been diagnosed with Crohn’s disease</li>
                  <li>Have moderately to severely active Crohn’s disease</li>
                </ul>
              </p>

              <p> Additional eligibility criteria will apply. </p>

              <p>
                All study-required visits, tests, and medication will be
                provided at no cost. Parents/guardians may be reimbursed for
                study-required travel.
              </p>

              <p>
                To learn more about participation in the UNITI Jr study or for
                information about pediatric research studies, please select a
                button below.{" "}
              </p>

              <p>
                <div id="responsive-btn">
                  <Link className="btn_about_uniti" to="/UNITIJR/about-study">
                    Learn About UNITI Jr
                  </Link>{" "}
                </div>
                <div id="responsive-btn">
                  <Link
                    className="btn_uniti_research"
                    to="/UNITIJR/about-research"
                  >
                    About Research Studies
                  </Link>{" "}
                </div>
                <div id="responsive-btn">
                  <Link className="btn_find_site" to="/find-a-site">
                    Find a study site
                  </Link>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-container">
        <footer className="wrapper clearfix">
          <div className="foot">
            <div className="JRD">
              <img alt="Janssen logo" src={"/"+JRD_logo_notag_edit} />
            </div>
            <Link to="/">Home</Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/UNITIJR/about-study"> About UNITI Jr</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/about-research">About Research Studies</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/privacy">Privacy Policy </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/legal"> Legal Notice</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br />© Janssen Research & Development, LLC 2021. This site is
            hosted and operated by QD Solutions, Inc. on behalf of Janssen
            Research & Development, LLC, who is solely responsible for its
            content. The site is intended for US residents only. Last Update:
            26Apr2021
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default UnitiHome;
