import React from "react";
import wavy_bg from "../images/wavy_bg.png";
import UNIFI_logo from "../images/UNIFI_logo.png";
import int_img_aboutresearch2 from "../images/int_img_aboutresearch2.png";
import JRD_logo_notag_edit from "../images/JRD_logo_notag_edit.png";
import { Link } from "react-router-dom";

const UnifiAboutResearch = () => {
  return (
    <React.Fragment>
      <div className="main-container">
        <div className="main wrapper clearfix">
          <div className="mn">
            <div className="lg">
              <img alt="UNIFI Jr study logo" src={UNIFI_logo} />
            </div>
            <div className="mn_image_new">
              <img alt="wavy lines background" src={"/"+wavy_bg} />
              {/* <div className="CRDPUChead_interior"> */}
              <div className="CRDPUChead">
                About <span className="CRDPUC_high_3">Research</span>{" "}
                <span className="CRDPUC_high_2">studies</span>
              </div>
              {/* <!--	</div> <!-- end of mn_image_new --> */}
            </div>
          </div>

          <div className="CRDPUC_copyholder_int">
            <div className="bodycopy" id="maincopy">
              <p>
                Although research study participants are observed and evaluated
                by medical professionals, the purpose of research studies is to
                gather information about an investigational medication. This is
                different than receiving regular medical care where the purpose
                is to treat a disease or condition.
              </p>

              <p>
                Research studies are conducted by doctors and researchers. By
                conducting these studies, they will learn more about an
                investigational medication’s safety and effectiveness.
              </p>

              <p>
                Once research studies of an investigational medication are
                completed, regulatory health agencies review the results and
                decide if the investigational medication could be approved and
                made available to the public.
              </p>

              <p>
                In order to conduct research studies, doctors need volunteers to
                participate. By participating in the UNIFI Jr study, your child
                may help advance research for ulcerative colitis.
              </p>
              <br />

              <p>
                <div id="responsive-btn">
                  <Link className="btn_about_unifi" to="/UNIFIJR/about-study">
                    Learn About UNIFI Jr
                  </Link>{" "}
                </div>
                <div id="responsive-btn">
                  <Link className="btn_find_site" to="/find-a-site">
                    Find a study site
                  </Link>
                </div>
              </p>
            </div>
          </div>
          <div className="int_pics">
            <img
              alt="illustration of doctor speaking with parent and child"
              src={"/"+int_img_aboutresearch2}
            />
          </div>
        </div>
      </div>

      <div className="footer-container">
        <footer className="wrapper clearfix">
          <div className="foot">
            <div className="JRD">
              <img alt="Janssen logo" src={"/"+JRD_logo_notag_edit} />
            </div>
            <Link to="/">Home</Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/UNIFIJR/about-study"> About UNIFI Jr</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/about-research">About Research Studies</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/privacy">Privacy Policy </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/legal"> Legal Notice</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br />© Janssen Research & Development, LLC 2021. This site is
            hosted and operated by QD Solutions, Inc. on behalf of Janssen
            Research & Development, LLC, who is solely responsible for its
            content. The site is intended for US residents only. Last Update:
            26Apr2021
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default UnifiAboutResearch;
