import React from "react";
import father_son_main from "../images/father_son_main.png";
import uniti_btn from "../images/uniti_btn.png";
import unifi_btn from "../images/unifi_btn.png";
import JRD_logo_notag_edit from "../images/JRD_logo_notag_edit.png";
import EmailFriend from "./EmailFriend";
import { Link } from "react-router-dom";
import { Player } from 'video-react';
import "./video-react.css";
import Immunology_Storyteller_video from '../videos/Immunology Storyteller Videos_Caregiver Version_FINAL_CRD3004 & PUC3001.mp4'
import IMM_Storyteller_video from '../videos/IMM Storyteller Videos_Patient Version_FINAL_CRD3004 & PUC3001.mp4'
import IMM_Storyteller_Trial_Coord_video from '../videos/IMM_Storyteller_Videos_Trial_Coordinator_Version.mp4'
import IMM_Storyteller_PI_video from '../videos/IMM_Storyteller_Videos_ PI_Version.mp4'
import Immunology_Storyteller_video_image from '../images/Immunology_Storyteller_video_image.png'
import IMM_Storyteller_video_image from '../images/IMM_Storyteller_video_image.png'
import IMM_Storyteller_PI_Version_Image from '../images/IMM_Storyteller_PI_Version_Image.png'
import IMM_Storyteller_Trial_Coord_Image from '../images/IMM_Storyteller_Trial_Coord_Image.png'

const Index = () => {
  return (
    <div className="main-container">
      <div className="main wrapper clearfix">
        <div className="mn">
          <div className="mn_image_new">
            <img
              alt="illustration of father talking to son"
              src={father_son_main}
            />
            <div className="CRDPUChead">
              <span className="CRDPUC_high_3">Together</span>, we can take{" "}
              <span className="CRDPUC_high_1">Crohn’s disease</span> and{" "}
              <span className="CRDPUC_high_2">ulcerative colitis</span> research
              further.
            </div>
          </div>
          <div className="CRDPUCsubhead">
            Medical research is evaluating an investigational medication for
            pediatric patients who have been diagnosed with{" "}
            <span className="CRDPUCsubhead_high">Crohn’s disease</span> or{" "}
            <span className="CRDPUCsubhead_high">ulcerative colitis</span>.
          </div>
          <EmailFriend />
          <div id="mobilebuttons">
            <div className="calloutbox_one">
              <div className="callout_main2" id="home_callout">
                <div className="callout_main2_headline">
                  Visit the UNITI Jr study website for pediatric{" "}
                  <span className="callout_highlight">Crohn’s disease</span>{" "}
                  patients
                  <p className='add-margin-top'>
                    <Link className="button" to="/UNITIJR">
                      Go to <img alt="UNITI Jr logo" src={uniti_btn} />
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="calloutbox_two">
              <div className="callout_main" id="home_callout">
                <div className="callout_main_headline">
                  Visit the UNIFI Jr study website for pediatric{" "}
                  <span className="callout_highlight">ulcerative colitis</span>{" "}
                  patients
                  <p className='add-margin-top'>
                    <Link className="button" to="/UNIFIJR/home">
                      Go to <img alt="UNIFI Jr logo" src={unifi_btn} />
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="calloutbox_three">
              <div className="callout_main3" id="home_callout3">
                <div className="callout_main3_headline">
                  Search for a participating study site near you
                  <br />
                  <br />
                  <p>
                    <Link className="button_alt" to="/find-a-site">
                      Find a site
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div
            className="copyholder_main"
            style={{ textAlign: "center", width: "88%", marginTop: "25px" }}
          >
            <div className="row">
              <div style={{marginTop: '10px'}} className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Player
                    playsInline
                    poster={Immunology_Storyteller_video_image}
                    src={Immunology_Storyteller_video}

                  />
              </div>
              <div style={{marginTop: '10px'}} className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Player
                  playsInline
                  poster={IMM_Storyteller_video_image}
                  src={IMM_Storyteller_video}
                  />
              </div>
            </div>
            <div className="row">
              <div style={{marginTop: '10px'}} className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Player
                    playsInline
                    poster={IMM_Storyteller_Trial_Coord_Image}
                    src={IMM_Storyteller_Trial_Coord_video}

                  />
              </div>
              <div style={{marginTop: '10px'}} className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Player
                  playsInline
                  poster={IMM_Storyteller_PI_Version_Image}
                  src={IMM_Storyteller_PI_video}
                  />
              </div>
            </div>
          </div>


        </div>
      </div>

      <div className="footer-container">
        <footer className="wrapper clearfix">
          <div className="foot">
            <div className="JRD">
              <img alt="Janssen logo" src={JRD_logo_notag_edit} />
            </div>
            <Link to="/">Home </Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/UNITIJR"> UNITI Jr Study</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/UNIFIJR">UNIFI Jr Study</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/find-a-site">Find A Study Site</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/privacy">Privacy Policy </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/legal"> Legal Notice</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br />© Janssen Research & Development, LLC 2021. This site is
            hosted and operated by QD Solutions, Inc. on behalf of Janssen
            Research & Development, LLC, who is solely responsible for its
            content. The site is intended for US residents only. Last Update:
            26Apr2021
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Index;
