import React, {useEffect} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from 'react-ga';
import Index from "./components/index";
import LegalNotice from "./components/LegalNotice";
import Privacy from "./components/Privacy";
import UnifiHome from "./components/UnifiHome";
import UnifiAboutResearch from "./components/UnifiAboutResearch";
import UnifiAboutStudy from "./components/UnifiAboutStudy";
import UnitiHome from "./components/UnitiHome";
import UnitiAboutResearch from "./components/UnitiAboutResearch";
import UnitiAboutStudy from "./components/UnitiAboutStudy";
import FindASite from "./components/FindASite";

function App() {
  useEffect(() => {
    ReactGA.initialize('G-5Z05FHTYHP')

    // to report page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <Router basename="CRDPUC">
      <Switch>
        <Route path={`/find-a-site`}>
          <FindASite />
        </Route>
        <Route path={`/UNIFIJR/about-research`}>
          <UnifiAboutResearch />
        </Route>
        <Route path={`/UNIFIJR/about-study`}>
          <UnifiAboutStudy />
        </Route>
        <Route path={`/UNIFIJR`}>
          <UnifiHome />
        </Route>
        <Route path={`/UNITIJR/about-research`}>
          <UnitiAboutResearch />
        </Route>
        <Route path={`/UNITIJR/about-study`}>
          <UnitiAboutStudy />
        </Route>
        <Route path={`/UNITIJR`}>
          <UnitiHome />
        </Route>
        <Route path={`/legal`}>
          <LegalNotice />
        </Route>
        <Route path={`/privacy`}>
          <Privacy />
        </Route>
        <Route path={`/`}>
          <Index />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
