import React from "react";
import { Link } from "react-router-dom";
import UNIFI_logo from "../images/UNIFI_logo.png";
import UNITI_logo from "../images/UNITI_logo.png";
import wavy_bg from "../images/wavy_bg.png";
import StoreMapper from "./StoreMapper";
import JRD_logo_notag_edit from "../images/JRD_logo_notag_edit.png";

const FindASite = () => {
  return (
    <React.Fragment>
      <div className="main-container">
        <div className="main wrapper clearfix">
          <div className="mn">
            <div className="lg_legal">
              <img
                alt="UNIFI Jr study logo"
                id="find-site-logo"
                src={UNIFI_logo}
              />
              <img
                alt="UNITI Jr study logo"
                id="find-site-logo"
                src={UNITI_logo}
              />
            </div>
            <div className="mn_image_new">
              <img alt="illustration of father talking to son" src={"/"+wavy_bg} />
              <div className="CRDPUChead_legal">
                Participating <span className="CRDPUC_high_3">Study</span>
                <span className="CRDPUC_high_2">Sites</span>
              </div>
            </div>
          </div>
          {/* <!-- end mn -->
        <!--
			</div> <!-- end main wrapper clearfix --> */}

          <div className="CRDPUC_copyholder_int">
            <div className="bodycopy" id="maincopy">
              <p>
                Please select a condition below to search for sites that are
                participating in a pediatric Crohn’s disease or ulcerative
                colitis research study.
              </p>

              <p>
                If you would like to learn more about a research study or talk
                to site staff about your child’s potential participation in that
                study, please contact the site nearest you.
              </p>
              <br />
              <div id="filter-text">Filter by:</div>
              <div id="storemapper" style={{ width: "100%" }}>
                <p>
                  Store Locator is loading from
                  <a href="https://www.storemapper.co">Storemapper</a>...
                </p>
              </div>

              <StoreMapper />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <footer className="wrapper clearfix">
          <div className="foot">
            <div className="JRD">
              <img alt="Janssen logo" src={"/"+JRD_logo_notag_edit} />
            </div>
            <Link to="/">Home </Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/UNITIJR"> UNITI Jr Study</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/UNIFIJR">UNIFI Jr Study</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/find-a-site">Find A Study Site</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/privacy">Privacy Policy </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/legal"> Legal Notice</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br />© Janssen Research & Development, LLC 2021. This site is
            hosted and operated by QD Solutions, Inc. on behalf of Janssen
            Research & Development, LLC, who is solely responsible for its
            content. The site is intended for US residents only. Last Update:
            26Apr2021
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default FindASite;
