import React, { useState } from "react";
import { Link } from "react-router-dom";
import mail_icon from "../images/mail_icon.png";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import validator from "validator";
import FormControl from "@material-ui/core/FormControl";
import ReCAPTCHA from "react-google-recaptcha";
import Grid from "@material-ui/core/Grid";

const api_path =
  "https://276iyfva31.execute-api.us-east-1.amazonaws.com/dev/submit";
const sendmail_api_path =
  "https://f46u6zbgc1.execute-api.us-east-1.amazonaws.com/dev/submit";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiTypography-body1": {
      fontSize: "0.75rem",
    },
    "& .PrivateSwitchBase-root-6": {
      padding: "2px",
      paddingRight: "8px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
      backgroundColor: "#f2f2f2",
      "& fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ff7f83",
      },
    },
    "& .MuiOutlinedInput-input": {
      //   padding: "0",
    },
    "& label.Mui-focused": {
      color: "#ff7f83",
    },
    formControl: {
      margin: theme.spacing(3),
    },
  },
  modal: {
    display: "block",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "90%",
    top: "8%",
    bottom: "8%",
    height: "80%",
    margin: "auto",
    textAlign: "center",
    // marginTop: "24px",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: 0,
  },
}));

const EmailFriend = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [captchaPass, setCaptchaPass] = useState(false);

  const setEmailValue = (input) => {
    setEmail(input.target.value);
  };

  const setNameValue = (input) => {
    if (input.target.value.length < 50) setName(input.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (value) => {
    const body = { "g-recaptcha-response": value };

    try {
      fetch(api_path, {
        method: "post",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(body),
      }).then((data) => {
        // can be undefined
        // console.log("captcha data:");
        // console.log(data);
        if (data.ok) setCaptchaPass(data.ok);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const sendMessage = () => {
    if (!captchaPass) {
      // console.log("captcha did not pass");
      return;
    } else if (!validator.isEmail(email)) {
      // console.log("email error");
      setEmailError(true);
    } else if (!validator.isAlphanumeric(name)) {
      // console.log("name error");
      setNameError(true);
    } else {
      //SEND MESSAGE SCRIPT
      let body = {
        email: email,
        name: name,
      };

      setOpen(false);
      // console.log(body);
      try {
        fetch(sendmail_api_path, {
          method: "post",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify(body),
        }).then((data) => {
          //mail sent
        });
      } catch (e) {
        // console.log(e);
      }
    }
  };


  return (
    <div id="content-container">
      <button id="email-button" onClick={handleOpen}>
        <img src={mail_icon} id="email-button-img" alt='Mail Icon' />
        <span>Email</span>
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h1 id="modal-header-text">
              Email <span style={{ color: "#ff7f83" }}>A Friend</span>
            </h1>
            <div style={{ backgroundColor: "#ff7f83", height: "16px" }} />
            <p id="modal-sub-text">
              <br />
              <React.Fragment>
                <b>
                  Pediatric Crohn’s Disease and Ulcerative Colitis Research
                  Studies{" "}
                </b>
                <br />
                <Link to="/" style={{ color: "#ff7f83" }}>
                  kidsIBDstudy.com
                </Link>
              </React.Fragment>
            </p>
            <div style={{ height: "24px" }} />
            <div id="modal-body">
              <p>
                Your friend’s email address
                <br />
                <FormControl
                  component="fieldset"
                  className={classes.root}
                  style={{ textAlign: "center", margin: "auto" }}
                >
                  <TextField
                    key="friendmail"
                    id="outlined-basic"
                    label="Your friend’s email address"
                    variant="outlined"
                    style={{ margin: "auto", marginTop: "8px" }}
                    value={email}
                    onChange={setEmailValue}
                    error={emailError}
                  />
                  {emailError && (
                    <p id="error-text">Please enter a valid email address.</p>
                  )}
                  <br />
                  <br />
                  Your name <br />
                  <TextField
                    key="name"
                    id="outlined-basic"
                    label="Your name"
                    variant="outlined"
                    style={{ margin: "auto", marginTop: "8px" }}
                    value={name}
                    onChange={setNameValue}
                    error={nameError}
                  />
                </FormControl>
                {nameError && <p id="error-text">Please enter a valid name.</p>}
                <br />
                <br />
              </p>
              <div style={{ maxWidth: "50%", margin: "auto" }}>
                <span id="modal-body-text">
                  Please ask us to contact only individuals you know and who you
                  believe wish to receive an email about this study. To provide
                  the service, we will use your name and your friend's email address only
                  to send the requested email.
                  <br />
                </span>
              </div>
              <br />
              <Grid id="captcha-container" container>
                <Grid item sm={4}></Grid>
                <Grid item sm={4}>
                  <ReCAPTCHA
                    sitekey="6LcnuZgaAAAAAFunmjKbbcE8WYUqFDAyPzz1uEgx"
                    onChange={onChange}
                  />
                  <Grid item sm={4}></Grid>
                </Grid>
              </Grid>
              <p>
                <br />
                <button
                  id="h2-button"
                  style={{
                    maxWidth: "70%",
                    margin: "auto",
                    backgroundColor: "#ff7f83",
                    color: "white",
                    border: "none",
                    borderRadius: "8px",
                    padding: "10%",
                    paddingTop: "2%",
                    paddingBottom: "2%",
                    fontSize: "2em",
                    fontWeight: "bold",
                  }}
                  onClick={sendMessage}
                >
                  Send
                </button>
              </p>
              <div style={{ height: "40px" }} />
            </div>
          </div>
        </Fade>
      </Modal>
      <div style={{ height: "40px" }} />
    </div>
  );
};

export default EmailFriend;
