import React from "react";
import UNITI_logo from "../images/UNITI_logo.png";
import UNIFI_logo from "../images/UNIFI_logo.png";
import wavy_bg from "../images/wavy_bg.png";
import JRD_logo_notag_edit from "../images/JRD_logo_notag_edit.png";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <React.Fragment>
      <div className="main-container">
        <div className="main wrapper clearfix">
          <div className="mn">
            <div className="lg_legal">
              <img alt="UNIFI Jr study logo" src={UNIFI_logo} />
              <img src={UNITI_logo} alt='Unit Logo' />
            </div>
            <div className="mn_image_new">
              <img alt="illustration of father talking to son" src={wavy_bg} />
              <div className="CRDPUChead_legal">
                PRIVACY <span className="CRDPUC_high_3">POLICY</span>
              </div>
            </div>
            {/* </div> */}
          </div>

          <div className="CRDPUC_copyholder_legal">
            <div className="bodycopy" id="maincopy">
              <p>
                Last Update: 5Feb2021
                <br />
                Janssen Research & Development, LLC cares about your privacy and
                wants you to be familiar with how we collect, use, and disclose
                information. This Privacy Policy describes our practices in
                connection with information that we or our service providers
                collect through the website or application (hereinafter the
                “Service”) which is operated and controlled by our partner, QD
                Solutions, Inc . By providing personal information on this
                website or by using the Service, you acknowledge that you have
                read and understand this Privacy Policy.
              </p>

              <p>
                Please note that the clinical research study, which this website
                is related to, is managed by Janssen Research & Development,
                which is a global organization that operates through different
                legal entities in various countries. Therefore, the legal entity
                acting as the sponsor for Janssen Research & Development studies
                may vary and may be different from the legal entity that is the
                owner of this website.
              </p>

              <p>
                <span className="legal_highlight_1">USE OF SITE BY MINORS</span>
                <br />
                The Service is not directed to individuals under the age of 18,
                and we request that these individuals not provide personal
                information through the Service. If your child has submitted
                Personal Information and you would like to request that such
                Personal Information be removed, please contact us as explained
                below under Contacting Us.
              </p>

              <p>
                <span className="legal_highlight_1">
                  INFORMATION COLLECTION
                </span>
                <br />
                The Site may ask you to submit personal information, such as
                your name, telephone number, email address or zip code in order
                for you to be contacted by a member of the study site staff or
                to locate a study site near you. You will be informed what
                information is required and what information is optional.
              </p>

              <p>
                If you submit any personal information relating to another
                individual to us, you represent that you have the authority to
                do so and to permit us to use the information in accordance with
                this Privacy Policy.
              </p>

              <p>
                <span className="legal_highlight_1">SENSITIVE INFORMATION</span>
                <br />
                Unless we specifically request or invite it, we ask that you not
                send us, and you not disclose, any sensitive personal
                information (e.g., Social Security numbers, information related
                to racial or ethnic origin, political opinions, religion or
                philosophical beliefs, health, sex life or sexual orientation,
                criminal background, or trade union membership, or biometric or
                genetic data for the purpose of uniquely identifying an
                individual) on or through the Service or otherwise to us.
              </p>

              <p>
                <span className="legal_highlight_1">
                  AUTOMATIC INFORMATION COLLECTION AND USE
                </span>
                <br />
                We and our service providers may also automatically collect and
                use information in the following ways:
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <strong>Through your browser:</strong> Certain information
                    is collected by most browsers, such as your Media Access
                    Control (MAC) address, computer type (Windows or Mac),
                    screen resolution, operating system name and version, and
                    Internet browser type and version. We may collect similar
                    information, such as your device type and identifier, if you
                    access the Service through a mobile device. We use this
                    information to ensure that the Service functions properly.
                  </li>

                  <li>
                    <strong>Using cookies:</strong> Cookies are pieces of
                    information stored directly on the computer you are using.
                    Cookies allow us to collect information such as browser
                    type, time spent on the Service, pages visited, and language
                    preferences. We and our service providers use the
                    information for security purposes, to facilitate navigation,
                    display information more effectively, and to personalize
                    your experience while using the Service. We also use cookies
                    to recognize your computer or device, which makes your use
                    of the Service easier, such as to remember what is in your
                    shopping cart. In addition, we use cookies to gather
                    statistical information about Service usage in order to
                    continually improve its design and functionality, understand
                    how individuals use it, and to assist us with resolving
                    questions regarding it. Cookies further allow us to select
                    which of our advertisements or offers are most likely to
                    appeal to you and display them while you are on the Service.
                    We may also use cookies in online advertising to track
                    consumer responses to our advertisements.
                    <br />
                    You can refuse to accept these cookies by following your
                    browser’s instructions; however, if you do not accept them,
                    you may experience some inconvenience in your use of the
                    Service. You may also not receive advertising or other
                    offers from us that are relevant to your interests and
                    needs. To learn more about cookies, please visit{" "}
                    <a href="http://www.allaboutcookies.org" target="_blank">
                      http://www.allaboutcookies.org
                    </a>
                    .
                  </li>

                  <li>
                    <strong>IP address:</strong> Your IP address is a number
                    that is automatically assigned to your computer by your
                    Internet Service Provider. An IP address is identified and
                    logged automatically in our server log files whenever a user
                    visits the Service, along with the time of the visit and the
                    pages visited. Collecting IP addresses is standard practice
                    and is done automatically by many online services. We use IP
                    addresses for purposes such as calculating Service usage
                    levels, diagnosing server problems, and administering the
                    Service. We may also derive your approximate location from
                    your IP address.
                  </li>

                  <li>
                    <strong>Device Information:</strong> We may collect
                    information about your mobile device, such as a unique
                    device identifier, to understand how you use the Service.
                  </li>
                </ul>
              </p>
              <p>
                <span className="legal_highlight_1">
                  How We Use and Disclose Information
                </span>
                <br />
                We use and disclose information you provide to us as described
                to you at the point of collection.
              </p>
              <p>
                Where required by applicable law, we will obtain your consent to
                our use of your personal information at the point of information
                collection. We may also use information from or about you as
                necessary to perform a contract, to comply with a legal
                obligation (for example, due to our pharmacovigilance
                obligations), or for our legitimate business interests. Our
                legitimate business interests may include any or all of the
                following:
                <ul>
                  <li>
                    to respond to your inquiries and fulfill your requests, such
                    as to send you documents you request or e mail alerts;
                  </li>
                  <li>
                    to send you important information regarding our relationship
                    with you or regarding the Service, changes to our terms,
                    conditions, and policies and/or other administrative
                    information; and for other business purposes, such as data
                    analysis, audits, developing new products, enhancing our
                    website, improving our products and services, identifying
                    Service usage trends, better understanding your interests so
                    that we can serve you better, personalizing your experience
                    on the Service by presenting products and offers tailored to
                    you, and determining the effectiveness of our promotional
                    campaigns.
                  </li>
                </ul>
                We also disclose information collected through the Site:{" "}
                <ul>
                  <li>
                    to our affiliates for the purposes described in this Privacy
                    Policy. The affiliate that is acting as the Sponsor in
                    relation to your country is the party responsible for the
                    management of the jointly-used Personal Information;{" "}
                  </li>
                  <li>
                    to our third-party service providers who provide services
                    such as website hosting and moderating, mobile application
                    hosting, data analysis, payment processing, order
                    fulfillment, infrastructure provision, IT services, customer
                    service, e-mail and direct mail delivery services, credit
                    card processing, auditing services, and other services, in
                    order to enable them to provide services; and
                  </li>
                  <li>
                    to a third party in the event of any reorganization, merger,
                    sale, joint venture, assignment, transfer, or other
                    disposition of all or any portion of our business, assets,
                    or stock (including in connection with any bankruptcy or
                    similar proceedings).
                  </li>
                </ul>
                In addition, we use and disclose information collected through
                the Site as we believe to be necessary or appropriate: (a) as
                permitted by applicable law, including laws outside your country
                of residence; (b) to comply with legal process; (c) to respond
                to requests from public and government authorities, including
                public and government authorities outside your country of
                residence; (d) to enforce our terms and conditions; (e) to
                protect our operations or those of any of our affiliates; (f) to
                protect our rights, privacy, safety, or property, and/or that of
                our affiliates, you, or others; and (g) to allow us to pursue
                available remedies or limit the damages that we may sustain. We
                may also use and disclose information collected through the Site
                in other ways, with your consent.{" "}
              </p>
              <p>
                We may use and disclose information we collect automatically as
                described above, under “Automatic Information Collection and
                Use.”
              </p>
              <p>
                In addition, where allowed by applicable law, we may use and
                disclose information that is not in personally identifiable form
                for any purpose. If we combine information that is not in
                personally identifiable form with information that is
                identifiable (such as combining your name with your geographical
                location), we will treat the combined information as personal
                information as long as it is combined.{" "}
              </p>
              <p>
                <span className="legal_highlight_1">CHOICES AND ACCESS</span>
                <br />
                <span className="legal_highlight_2">
                  How you can access, change, or delete your personal
                  information
                </span>
                <br />
                If you would like to review, correct, update, restrict, or
                delete your personal information, or if you would like to
                request to receive an electronic copy of your personal
                information for purposes of transmitting it to another company
                (to the extent these rights are provided to you by applicable
                law), please contact us via email at{" "}
                <a href="mailto:privacy@qdsolutions.com">
                  privacy@qdsolutions.com
                </a>
                . We will respond to your request as soon as reasonably
                practicable and no later than one month after receipt. If
                circumstances cause any delay in our response, you will be
                promptly notified and provided a date for our response.
              </p>
              <p>
                <span className="legal_highlight_1">CROSS-BORDER TRANSFER</span>
                <br />
                Your personal information may be stored and processed in any
                country where we have facilities or service providers, and by
                using our Service or by providing consent to us (where required
                by law), you agree to the transfer of information to countries
                outside of your country of residence, including to the United
                States, which may provide for different data protection rules
                than in your country. Appropriate contractual and other measures
                are in place to protect personal information when it is
                transferred to our affiliates or third parties in other
                countries.
              </p>
              <p>
                <span className="legal_highlight_1">SECURITY</span>
                <br />
                We seek to use reasonable organizational, technical, and
                administrative measures designed to protect personal information
                under our control. Unfortunately, no data transmission over the
                Internet or data storage system can be guaranteed to be 100%
                secure. If you have reason to believe that your interaction with
                us is no longer secure (for example, if you feel that the
                security of any account you have with us has been compromised),
                please immediately notify us in accordance with the “Contacting
                Us” section below.
              </p>
              <p>
                <span className="legal_highlight_1">RETENTION PERIOD</span>
                <br />
                We will retain your personal information for as long as needed
                or permitted in light of the purpose(s) for which it was
                obtained. The criteria used to determine our retention periods
                include: (i) the length of time we have an ongoing relationship
                with you and provide the Service to you; (ii) whether there is a
                legal obligation to which we are subject; and (iii) whether
                retention is advisable in light of our legal position (such as
                in regard to applicable statutes of limitations, litigation, or
                regulatory investigations).
              </p>
              <p>
                For example, if we collect your personal information for your
                participation in a Clinical Trial, we may need to store it for a
                period of life time of the investigational product that is in
                scope of the Clinical Trial + 20 years.
              </p>
              <p>
                Please note, however, that this is not an exhaustive list of
                retention periods. Your personal information may be stored for a
                longer period using the criteria set forth in the first
                paragraph of this section, especially points (ii) and (iii).
              </p>
              <p>
                <span className="legal_highlight_1">
                  THIRD PARTY SITES AND SERVICES
                </span>
                <br />
                This Privacy Policy does not address, and we are not responsible
                for, the privacy, information, or other practices of any third
                parties, including any third party operating any site or online
                service (including, without limitation, any application) that is
                available through this Service or to which this Service contains
                a link. The availability of, or inclusion of a link to, any such
                site or property on the Service does not imply endorsement of it
                by us or by our affiliates.
              </p>
              <p>
                <span className="legal_highlight_1">CONTACTING US</span>
                <br />
                Janssen Research & Development, LLC, located at 920 Route 202,
                Raritan, NJ 08869, United States, is the company responsible for
                collection, use, and disclosure of personal information under
                this Privacy Policy.
              </p>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us via e-mail at{" "}
                <a href="mailto:privacy@qdsolutions.com">
                  privacy@qdsolutions.com
                </a>
                .
              </p>
              <p>
                <span className="legal_highlight_1">
                  UPDATES TO THIS PRIVACY POLICY
                </span>
                <br />
                We may change this Privacy Policy. Any changes to this Privacy
                Policy will become effective when we post the revised Privacy
                Policy on the Service. Your use of the Service following these
                changes means that you accept the revised Privacy Policy. We
                recommend that you regularly review the Privacy Policy when you
                visit the Service. This policy was last updated on 5Feb2021.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-container">
        <footer className="wrapper clearfix">
          <div className="foot">
            <div className="JRD">
              <img alt="Janssen logo" src={JRD_logo_notag_edit} />
            </div>
            <Link to="/">Home </Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/UNITIJR"> UNITI Jr Study</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/UNIFIJR">UNIFI Jr Study</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/find-a-site">Find A Study Site</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/privacy">Privacy Policy </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/legal"> Legal Notice</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br />© Janssen Research & Development, LLC 2021. This site is
            hosted and operated by QD Solutions, Inc. on behalf of Janssen
            Research & Development, LLC, who is solely responsible for its
            content. The site is intended for US residents only. Last Update:
            26Apr2021
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default Privacy;
