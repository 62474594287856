import React from "react";
import wavy_bg from "../images/wavy_bg.png";
import UNITI_logo from "../images/UNITI_logo.png";
import int_img_aboutuniti from "../images/int_img_aboutuniti.png";
import JRD_logo_notag_edit from "../images/JRD_logo_notag_edit.png";
import { Link } from "react-router-dom";

const UnitiAboutStudy = () => {
  return (
    <React.Fragment>
      <div className="main-container">
        <div className="main wrapper clearfix">
          <div className="mn">
            <div className="lg">
              <img alt="UNITI Jr study logo" src={UNITI_logo} />
            </div>
            <div className="mn_image_new">
              <img alt="wavy lines background" src={"/"+wavy_bg} />
              {/* <div className="CRDPUChead_interior"> */}
              <div className="CRDPUChead">
                About the <span className="CRDPUC_high_1">UNITI Jr</span>{" "}
                <span className="CRDPUC_high_2">study</span>
              </div>
              {/* <!--	</div> <!-- end of mn_image_new --> */}
            </div>
            {/* <!--
			</div> <!-- end main wrapper clearfix --> */}

            <div className="CRDPUC_copyholder_int">
              <div className="bodycopy" id="maincopy">
                <p>
                  The investigational medication being evaluated in this study
                  has not been approved to treat pediatric Crohn’s disease
                  patients. All patients who are eligible for this study and
                  agree to participate will initially receive a single
                  intravenous infusion of the investigational medication (also
                  known as an IV, which is when medication is delivered through
                  a small tube into a vein).
                </p>

                <p>
                  Following the infusion, patients will be randomly assigned
                  (like flipping a coin) to 1 of 2 study groups. Patients in
                  both groups will receive subcutaneous injections (needle
                  inserted just under the skin) of the investigational
                  medication every 8 to 12 weeks for 44 weeks.{" "}
                </p>

                <p>
                  Total participation in this study will last approximately 74
                  weeks (about 1.5 years), which includes regular visits to the
                  study clinic for tests and assessments.
                </p>

                <p>
                  The results of this study will help doctors and researchers
                  learn more about the investigational medication and whether it
                  could one day potentially be used to treat Crohn’s disease in
                  children and adolescents.
                </p>
                <br />

                <p>
                  <div id="responsive-btn">
                    <Link
                      className="btn_uniti_research"
                      to="/UNITIJR/about-research"
                    >
                      About Research Studies
                    </Link>{" "}
                  </div>
                  <div id="responsive-btn">
                    <Link className="btn_find_site" to="/find-a-site">
                      Find a study site
                    </Link>
                  </div>
                </p>
              </div>
            </div>
            <div className="int_pics">
              <img
                alt="illustration of doctor visit with parent and child"
                src={"/"+int_img_aboutuniti}
              />
            </div>
          </div>
        </div>

        <div className="footer-container">
          <footer className="wrapper clearfix">
            <div className="foot">
              <div className="JRD">
                <img alt="Janssen logo" src={"/"+JRD_logo_notag_edit} />
              </div>
              <Link to="/">Home</Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/UNITIJR/about-study"> About UNITI Jr</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/about-research">About Research Studies</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/privacy">Privacy Policy </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/legal"> Legal Notice</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <br />© Janssen Research & Development, LLC 2021. This site is
              hosted and operated by QD Solutions, Inc. on behalf of Janssen
              Research & Development, LLC, who is solely responsible for its
              content. The site is intended for US residents only. Last Update:
              26Apr2021
            </div>
          </footer>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UnitiAboutStudy;
