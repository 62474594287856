import React from "react";
import Script from "react-load-script";

const StoreMapper = () => {
  const insertMap = () => {
    var script = document.createElement("script");
    var entry = document.getElementsByTagName("script")[0];
    entry.parentNode.insertBefore(script, entry);
  };
  return (
    <React.Fragment>
      <Script
        data-storemapper-start="2021,03,26"
        data-storemapper-id="10112"
        async={true}
        url="https://www.storemapper.co/js/widget.js"
        onLoad={insertMap}
      />
      {/* <script
              data-storemapper-start="2021,03,26"
              data-storemapper-id="10112"
            >
              (function () {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.async = true;
                script.src = "https://www.storemapper.co/js/widget.js";
                var entry = document.getElementsByTagName("script")[0];
                entry.parentNode.insertBefore(script, entry);
              })();
            </script> */}
    </React.Fragment>
  );
};

export default StoreMapper;
